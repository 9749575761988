import keyMirror from 'keymirror';

const types = keyMirror({
  CREATE_SESSION: null,
  UPDATE_SESSION: null,
  GET_ALL_SESSIONS: null,
  GET_CURRENT_WEEK_SESSIONS: null,
  GET_SESSION: null,
  UPDATE_SESSION_FORM: null,
  RESET_SESSION_FORM: null,
  UPDATE_REVISION_FORM: null,
  RESET_REVISION_FORM: null,
  SET_ANNOUNCEMENT_CONTINUATION: null,
  SET_SESSION_SUBMITTED: null,
});

export default types;
