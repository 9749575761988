import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
// Types
import types from './types';
import AuthTypes from '../auth/types';
// Helpers
import {SocialLink} from '@brightlive/shared/helpers/interfaces';

export interface UserFormInitialState {
  id: string;
  displayName: string;
  avatar: string;
  profilePicture: string;
  backgroundImage: string;
  shortDescription: string;
  longDescription: string;
  socialLinks: SocialLink[];
  tags: string[];
  success: unknown;
  error: unknown;
}

const UserFormInitialState: UserFormInitialState = {
  id: '',
  displayName: '',
  avatar: '',
  profilePicture: '',
  backgroundImage: '',
  shortDescription: '',
  longDescription: '',
  socialLinks: [],
  tags: [],
  success: null,
  error: null,
};

const userForm = (state = UserFormInitialState, action: AnyAction) => {
  switch (action.type) {
    case `${types.UPDATE_USER_FORM}`:
    case `${AuthTypes.UPDATE_CURRENT_USER}`:
      return assign({}, state, {
        ...action?.data,
        error: null,
        success: null,
      });
    default:
      return state;
  }
};

interface UserState {
  userForm: UserFormInitialState;
}
export interface UserReducer {
  user: UserState;
}

export default combineReducers({
  userForm,
});
