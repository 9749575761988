import React, {useState, useEffect, useContext, createContext} from 'react';
import firebase from 'firebase/compat/app';
import {useDispatch} from 'react-redux';
import {setAuthToken} from 'redux/auth/actions';

const AuthContext = createContext<{
  user: firebase.User | null;
}>({
  user: null,
});

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function AuthProvider({children}: any) {
  const [user, setUser] = useState<firebase.User | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      // Remove session cookie if user does not exist
      if (!user) {
        setUser(null);
        dispatch(setAuthToken(''));
        return;
      }
    });
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{user}}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
