import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
import userTypes from '../user/types';
// Helpers
import {
  User,
  OnboardingSteps,
  SPMAgent,
} from '@brightlive/shared/helpers/interfaces';

interface AuthInitState {
  continueUrl: string | null;
  authToken: string | null;
  error: unknown;
}

const initAuthState: AuthInitState = {
  continueUrl: null,
  authToken: null,
  error: null,
};

const auth = (state = initAuthState, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_CONTINUE_URL}`: {
      return {
        ...state,
        continueUrl: action.url,
      };
    }
    case `${types.SET_AUTH_TOKEN}`: {
      return {
        ...state,
        authToken: action.authToken,
      };
    }
    case `${types.LOGOUT}_SUCCESS`:
      return initAuthState;
    default:
      return state;
  }
};

export type UserInitState = User & {
  onboardingSteps: OnboardingSteps;
  managedUsers: UserInitState[] | null;
  spm?: SPMAgent;
  self: UserInitState | null;
  managing: string;
  success: unknown;
  updated: boolean;
  deleted: boolean;
  error: Record<string, unknown>;
};

const userInitState: UserInitState = {
  email: '',
  firstName: '',
  lastName: '',
  displayName: '',
  phone: '',
  phoneVerified: false,
  avatar: '',
  agreedToPrivacy: null,
  agreedToTerms: null,
  favorites: [],
  id: '',
  uid: '',
  slug: null,
  agreedToWatchDisclosure: false,
  emailVerified: false,
  roles: 'supporter',
  onboardingSteps: {
    createdPassword: false,
    agreedToTerms: false,
    meetingInfo: {
      startTime: '',
      endTime: '',
      eventUri: '',
      cancelUrl: '',
      rescheduleUrl: '',
    },
    scheduledMeetingStatus: null,
    debutSessionStatus: null,
    statusScreen: null,
  },
  stripePayoutEnabled: false,
  stripeChargesEnabled: false,
  passwordUpdatedAt: '',
  backgroundImage: '',
  profilePicture: '',
  shortDescription: '',
  longDescription: '',
  tags: [],
  socialLinks: [],
  managing: '',
  managedUsers: null,
  self: null,
  success: null,
  updated: false,
  deleted: false,
  error: {},
};

const currentUser = (state = userInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_CURRENT_USER}_REQUEST`:
    case `${userTypes.UPDATE_USER}_REQUEST`:
    case `${userTypes.RESET_USER_STATE}`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
        deleted: false,
      });
    case `${types.GET_CURRENT_USER}_SUCCESS`:
    case `${types.UPDATE_CURRENT_USER}`:
      return assign({}, userInitState, {
        ...action?.data,
        error: null,
        success: true,
        updated: false,
        deleted: false,
      });
    case `${userTypes.UPDATE_USER}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: null,
          success: true,
          updated: true,
          deleted: false,
        }
      );
    case `${types.LOGOUT}_SUCCESS`:
      return userInitState;
    case `${types.GET_CURRENT_USER}_FAILURE`:
    case `${userTypes.UPDATE_USER}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error,
        updated: false,
        deleted: false,
      });
    default:
      return state;
  }
};
// Auth state
export interface AuthState {
  currentUser: UserInitState;
  auth: AuthInitState;
}
// Used for redux selector hooks
export interface AuthReducer {
  auth: AuthState;
}

export default combineReducers({
  auth,
  currentUser,
});
